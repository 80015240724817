import React from "react"

import SEO from "../components/seo"
import Content from '../components/Content/content';

const NotFoundPage = () => (
	<Content>
		<SEO title="Lektorat Kasper - freie Lektorin aus Hamburg"/>
		<h1>404: Seite nicht gefunden</h1>
		<p className="m-section__text">Die gesuchte Seite scheinte es nicht zu geben.</p>
	</Content>
)

export default NotFoundPage
